import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';

export default defineNuxtPlugin(() => {
  // when app is created in browser, restore SSR state from nuxt payload
  if (import.meta.client) {
    dayjs.extend(localizedFormat);
    dayjs.extend(advancedFormat);
    dayjs().format('L LT');
  }
});
