
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexzDglvnxNvoMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/index.vue?macro=true";
import { default as fileQ1uGAfNTa3Meta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/file.vue?macro=true";
import { default as marketingZr1gDM7KfGMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/marketing.vue?macro=true";
import { default as reportingUGagXaRaVmMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/reporting.vue?macro=true";
import { default as _91policy_93KvPohwMzMgMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/roster/[policy].vue?macro=true";
import { default as indexDLtjMSJU0ZMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/roster/index.vue?macro=true";
import { default as _91user_uuid_93xmuItMAkhRMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/team/[user_uuid].vue?macro=true";
import { default as indexZ3VCvtNRBmMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/team/index.vue?macro=true";
import { default as index1sMDWISTZJMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/refund/index.vue?macro=true";
import { default as _91plan_93JGhjXckCBpMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/roster/[plan].vue?macro=true";
import { default as indexmNOANoSWhLMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/roster/index.vue?macro=true";
import { default as indexfPNCyAaiYTMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/index.vue?macro=true";
import { default as loginavBhLhW3igMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/login.vue?macro=true";
import { default as email_45phone608h3LcRQSMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/email-phone.vue?macro=true";
import { default as indexJSVUlhwslvMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/index.vue?macro=true";
import { default as passwordm1ouNjZiqXMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/password.vue?macro=true";
import { default as set_45password7IkRdqQ01oMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/set-password.vue?macro=true";
import { default as _91id_934OG7hRvVLvMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/support/[id].vue?macro=true";
import { default as index3xMEPIFxCvMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/support/index.vue?macro=true";
import { default as addlp8L1aBhiEMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/add.vue?macro=true";
import { default as filenAg4mzxSndMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/file.vue?macro=true";
import { default as listkipZ8R0oQzMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/list.vue?macro=true";
import { default as risk_45profileT6XCpI1WgUMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/risk-profile.vue?macro=true";
import { default as indexoZFjSBzpqdMeta } from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/welcome/index.vue?macro=true";
export default [
  {
    name: "uuid",
    path: "/:uuid()",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/index.vue")
  },
  {
    name: "uuid-renters-file",
    path: "/:uuid()/renters/file",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/file.vue")
  },
  {
    name: "uuid-renters-marketing",
    path: "/:uuid()/renters/marketing",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/marketing.vue")
  },
  {
    name: "uuid-renters-reporting",
    path: "/:uuid()/renters/reporting",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/reporting.vue")
  },
  {
    name: "uuid-renters-roster-policy",
    path: "/:uuid()/renters/roster/:policy()",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/roster/[policy].vue")
  },
  {
    name: "uuid-renters-roster",
    path: "/:uuid()/renters/roster",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/renters/roster/index.vue")
  },
  {
    name: "uuid-team-user_uuid",
    path: "/:uuid()/team/:user_uuid()",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/team/[user_uuid].vue")
  },
  {
    name: "uuid-team",
    path: "/:uuid()/team",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/team/index.vue")
  },
  {
    name: "uuid-tuition-refund",
    path: "/:uuid()/tuition/refund",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/refund/index.vue")
  },
  {
    name: "uuid-tuition-roster-plan",
    path: "/:uuid()/tuition/roster/:plan()",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/roster/[plan].vue")
  },
  {
    name: "uuid-tuition-roster",
    path: "/:uuid()/tuition/roster",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/[uuid]/tuition/roster/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginavBhLhW3igMeta || {},
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/login.vue")
  },
  {
    name: "profile-email-phone",
    path: "/profile/email-phone",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/email-phone.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/index.vue")
  },
  {
    name: "profile-password",
    path: "/profile/password",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/profile/password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45password7IkRdqQ01oMeta || {},
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/set-password.vue")
  },
  {
    name: "support-id",
    path: "/support/:id()",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/support/[id].vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/support/index.vue")
  },
  {
    name: "vendors-add",
    path: "/vendors/add",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/add.vue")
  },
  {
    name: "vendors-file",
    path: "/vendors/file",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/file.vue")
  },
  {
    name: "vendors-list",
    path: "/vendors/list",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/list.vue")
  },
  {
    name: "vendors-risk-profile",
    path: "/vendors/risk-profile",
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/vendors/risk-profile.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: indexoZFjSBzpqdMeta || {},
    component: () => import("/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/pages/welcome/index.vue")
  }
]