export default defineAppConfig({
  ui: {
    primary: 'gg-blue',
    gray: 'slate',
    skeleton: {
      background: 'bg-gray-200 dark:bg-gray-600',
    },
    formGroup: {
      error: 'cy-error-class',
    },
    input: {
      size: {
        sm: 'text-md',
        md: 'text-md',
        lg: 'text-md',
      },
    },
    select: {
      size: {
        '2xs': 'text-md',
        xs: 'text-md',
        sm: 'text-md',
        md: 'text-md',
        lg: 'text-md',
        xl: 'text-base',
      },
    },
    selectMenu: {
      empty: 'text-md',
      option: {
        size: 'text-md',
      },
    },
    modal: {
      container: 'items-center',
      wrapper: 'z-[100]',
    },
    tooltip: {
      width: 'max-w-xs',
      background: 'bg-primary-600 dark:bg-primary-600',
      base: 'text-clip h-auto flex justify-center items-center p-2 text-sm w-52 opacity-95 text-white text-center',
      arrow: {
        background: 'before:bg-primary-600 dark:before:bg-primary-600',
      },
      ring: 'ring-primary-600 dark:ring-primary-600',
    },
  },
  // hack https://github.com/nuxt/icon/issues/117#issuecomment-1797833117
  nuxtIcon: {},
});
