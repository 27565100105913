const enUS = {
  required: {
    tc: 'The terms and conditions must be accepted',
    field: 'This field is required',
    select: 'Please select an option',
    generic: 'This value is required',
    minChar: 'Min {n} chars',
    minNum: 'Min {n} numbers',
    maxChar: 'Max {n} chars',
    maxNum: 'Max {n} numbers',
    firstName: 'First name is required',
    lastName: 'Last name is required',
    preferredName: 'Preferred name is required',
    name: 'Name is required',
    insuredName: 'Insured name is required',
    email: 'Email is required',
    password: 'Password is required',
    confirmationPassword: 'Confirmation password is required',
    currentPassword: 'Your current password is required',
    paymentInterval: 'Policy is required',
    autorenew: 'Autorenew is required',
    creditCard: {
      req: 'Card number is required',
      min: 'Must be at least 14 digits',
      max: 'Must be at most 16 digits',
    },
    expiration: {
      req: 'Expiration date is required',
      month: 'Month 01 to 12',
      expired: 'Expired',
      notValid: 'Invalid',
    },
    interestedParty: {
      companyName: 'Company name is required',
    },
    cvc: {
      req: 'CVC is required',
      min: 'Must be at least 3 digits',
      max: 'Must be at most 4 digits',
    },
    phone: 'Phone is required',
    phoneCountry: 'Phone country is required',
    address: 'Address is required',
    billAddress: 'Billing address is required',
    city: 'City is required',
    billCity: 'Billing city is required',
    state: 'State is required',
    billState: 'Billing state is required',
    zip: 'Zip code is required',
    billZip: 'Billing zip code is required',
    country: 'Country is required',
    billCountry: 'Billing country is required',
    claimant: 'Claimant is required',
    dob: {
      month: 'Month required',
      day: 'Day required',
      year: 'Year required',
    },
    startDate: {
      req: 'Start date is required',
      tomorrowLater: 'Error: The start date must be tomorrow or later',
      before: 'Start date must be before {date}',
    },
    endDate: {
      required: 'End date is required',
    },
    date: {
      req: 'Date is required',
      min: 'Date must be {date} or after.',
      max: 'Date must be {date} or before.',
    },
    money: {
      min: 'Amount must be {amount} or higher',
      max: 'Amount must be {amount} or lower',
    },
    lossDescription: 'Loss description is required',
    lossDate: 'Loss date is required',
    onCampus: 'On-campus is required',
  },
  errors: {
    invalidChars: 'Invalid chars',
    invalidAddress: 'Invalid address',
    invalidCity: 'Invalid city',
    invalidState: 'Invalid state',
    invalidZip: 'Invalid zip code',
    invalidCVC: 'Invalid CVC',
    invalidEmail: 'Invalid email',
    invalidCC: 'Credit Card number is invalid',
    invalidFirstName: 'Please enter valid first name',
    invalidLastName: 'Please enter valid last name',
    invalidPhone: 'Please enter valid phone',
    amountMaxEqual: 'Amount should be {amount} or lower',
    amountMinEqual: 'Amount should be {amount} or higher',
    password: {
      uppercase: 'The password must contain at least one uppercase letter',
      lowercase: 'The password must contain at least one lowercase letter',
      number: 'The password must contain at least one number',
      specialChar: 'The password must contain at least one special character ({characters})',
      match: 'Passwords must match',
      noMatch: 'New password must be different from the old password',
    },
  },
  password: {
    letterStart: 'Start with a letter',
    uppercase: 'At least one upper-case letter',
    lowercase: 'At least one lower-case letter',
    number: 'At least one number',
    specialChar: 'At least one special character {characters}',
    min: 'Minimum of {n} characters with at least one number or symbol',
    secure: 'Secure password',
    uncompromised: 'A secure password needs to be an uncommon password that is not easy to replicate',
  },
  property: 'Property',
  liability: 'Liability',
  deductible: 'Deductible',
  earthquake: 'Earthquake',
  propertyInclude: 'Your university has included property with your tuition.',
  liabilityInclude: 'Your university has included liability with your tuition.',
};

export default enUS;
