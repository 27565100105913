// eslint-disable-next-line import/no-extraneous-dependencies
import { type RuntimeConfig } from 'nuxt/schema';
import { domains, type environmentNames } from 'ui/config/constants';

export interface ENVValues {
  app_env: environmentNames,
  app_url: string,
  api_url: string,
}

export const getConfig = (allEnv: RuntimeConfig) => {
  const env = allEnv.public as unknown as ENVValues;
  const config = {
    env: env.app_env,
    isLocal: env.app_env === 'local',
    domains: domains[env.app_env],
    appURL: env.app_url,
    api: env.api_url,
  };
  return config;
};
