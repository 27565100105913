<!-- eslint-disable vue/max-len -->
<template>
  <svg width="326" height="186" viewBox="0 0 326 186" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1981_8074)">
      <path d="M254.001 171.125C280.021 171.514 296.332 149.443 296.308 135.874C296.281 121.214 285.122 108.164 278.552 94.4904C272.735 82.3722 274.869 68.6167 265.888 57.1799C254.955 43.2707 230.4 39.182 209.512 34.2943C182.504 27.9623 154.053 22.3631 124.894 24.5099C104.363 26.0175 84.2145 31.5414 69.8502 41.1087C58.5496 48.6468 51.3719 58.1267 43.1711 67.0397C34.6873 76.2693 23.7177 82.3722 13.1424 90.3233C-16.468 112.585 9.08259 152.678 38.8495 169.196L254.001 171.125Z" fill="#E8EDF8" />
    </g>
    <g clip-path="url(#clip1_1981_8074)">
      <path d="M163 185.275C253.022 185.275 326 181.159 326 176.082C326 171.005 253.022 166.889 163 166.889C72.9776 166.889 0 171.005 0 176.082C0 181.159 72.9776 185.275 163 185.275Z" fill="#B0C5E6" />
    </g>
    <g clip-path="url(#clip2_1981_8074)">
      <path d="M177.321 72.104L170.386 70.3138L174.904 66.9441L178.725 66.1426L180.586 68.3598L177.321 72.104Z" fill="#BE9168" />
      <path d="M147.635 158.508C147.635 158.508 149.355 168.547 149.391 168.67C149.426 168.793 148.764 169.869 148.764 169.869L146.055 170.355L143.58 169.22C143.58 169.22 142.152 161.123 141.895 159.859C141.637 158.596 147.635 158.508 147.635 158.508Z" fill="#BE9168" />
      <path d="M143.609 169.22C143.609 169.22 146.734 170.308 149.402 168.5C149.402 168.5 159.807 172.402 159.491 175.362C159.491 175.362 159.929 176.246 155.394 176.059C150.859 175.871 143.884 175.667 143.691 175.561C143.498 175.456 142.655 175.936 143.609 169.22Z" fill="#29296E" />
      <path d="M116.744 158.509C116.744 158.509 114.07 168.548 114.164 168.671C114.257 168.794 113.537 169.87 113.537 169.87L110.822 170.356L108.353 169.221C108.353 169.221 109.699 160.96 109.441 159.696C109.184 158.433 116.744 158.509 116.744 158.509Z" fill="#BE9168" />
      <path d="M108.353 169.22C108.353 169.22 111.478 170.308 114.146 168.5C114.146 168.5 124.55 172.402 124.228 175.362C124.228 175.362 124.673 176.246 120.167 176.059C115.661 175.871 108.657 175.667 108.464 175.561C108.271 175.456 107.399 175.936 108.353 169.22Z" fill="#29296E" />
      <path d="M144.844 95.7394L150.28 164.463H140.917C140.917 164.463 131.04 110.16 129.665 109.921C128.289 109.681 118.254 164.463 118.254 164.463H107.533L113.016 93.9609L144.844 95.7394Z" fill="#101121" />
      <path d="M123.766 42.1973C123.766 42.1973 135.323 42.5951 140.01 46.1521C142.936 48.3635 143.586 76.2519 143.586 76.2519L145.341 97.0556C145.341 97.0556 126.616 103.403 111.355 97.0556L109.693 83.1435C109.693 83.1435 103.666 48.1763 108.353 46.117C117.253 42.1973 123.766 42.1973 123.766 42.1973Z" fill="#167D2A" />
      <path d="M131.455 28.6672C131.455 28.6672 132.04 27.6258 132.567 27.9827C133.966 28.8778 132.778 30.3228 131.929 30.6797L131.455 28.6672Z" fill="#8C634D" />
      <path d="M131.414 24.9984C131.414 24.899 131.414 24.7936 131.414 24.6883C131.3 23.4994 130.921 22.351 130.307 21.3267C129.692 20.3024 128.857 19.428 127.862 18.7672C126.867 18.1064 125.737 17.6757 124.554 17.5067C123.371 17.3376 122.166 17.4343 121.025 17.7898C119.885 18.1453 118.838 18.7506 117.961 19.5617C117.084 20.3728 116.399 21.3692 115.956 22.4783C115.513 23.5875 115.322 24.7815 115.399 25.9735C115.476 27.1654 115.817 28.3253 116.399 29.3686C117.312 31.1705 118.113 32.715 118.927 33.9786C120.261 36.0847 120.877 38.5663 120.682 41.0516L120.583 42.5259C125.738 44.6905 127.71 42.5259 127.71 42.5259L127.476 39.0157C127.462 38.7927 127.532 38.5725 127.673 38.3991C127.814 38.2256 128.015 38.1116 128.237 38.0797C134.264 37.0851 131.666 27.748 131.414 24.9984Z" fill="#BE9168" />
      <path d="M123.555 29.9471C123.542 29.7996 123.485 29.6594 123.392 29.5441C123.299 29.4289 123.174 29.3438 123.033 29.2997C122.891 29.2555 122.74 29.2543 122.598 29.2961C122.456 29.3379 122.329 29.421 122.234 29.5347C122.139 29.6484 122.08 29.7877 122.065 29.9349C122.049 30.0822 122.077 30.2308 122.146 30.362C122.215 30.4932 122.321 30.601 122.451 30.6719C122.581 30.7428 122.729 30.7736 122.877 30.7603C122.975 30.7515 123.07 30.7234 123.157 30.6778C123.244 30.6321 123.321 30.5698 123.385 30.4943C123.448 30.4187 123.495 30.3316 123.524 30.2377C123.554 30.1438 123.564 30.045 123.555 29.9471Z" fill="#101121" />
      <path d="M130.051 28.0227C130.037 27.875 129.98 27.7346 129.887 27.6194C129.794 27.5041 129.668 27.4191 129.526 27.3753C129.385 27.3314 129.233 27.3306 129.091 27.373C128.949 27.4154 128.822 27.499 128.728 27.6133C128.633 27.7276 128.575 27.8674 128.56 28.0149C128.545 28.1625 128.574 28.3112 128.643 28.4421C128.713 28.5731 128.82 28.6804 128.951 28.7506C129.082 28.8207 129.23 28.8504 129.378 28.8359C129.476 28.8271 129.571 28.7991 129.657 28.7533C129.744 28.7076 129.821 28.6451 129.884 28.5695C129.946 28.4939 129.993 28.4067 130.022 28.3128C130.051 28.219 130.06 28.1204 130.051 28.0227Z" fill="#101121" />
      <path d="M120.776 29.0296C120.697 29.0361 120.619 29.0219 120.548 28.9886C120.443 28.9381 120.363 28.8481 120.324 28.7384C120.286 28.6288 120.292 28.5083 120.343 28.4036C120.622 27.8544 121.107 27.4377 121.692 27.2438C122.277 27.05 122.915 27.0946 123.468 27.3681C123.526 27.3857 123.579 27.4151 123.626 27.4545C123.672 27.494 123.709 27.5425 123.736 27.5972C123.762 27.6518 123.777 27.7113 123.779 27.772C123.781 27.8326 123.771 27.8931 123.749 27.9495C123.726 28.0059 123.692 28.0571 123.649 28.0998C123.606 28.1425 123.555 28.1758 123.498 28.1976C123.441 28.2193 123.381 28.2291 123.32 28.2263C123.26 28.2235 123.2 28.2082 123.146 28.1813C122.975 28.0873 122.788 28.0279 122.594 28.0064C122.401 27.985 122.205 28.0019 122.018 28.0563C121.831 28.1106 121.656 28.2013 121.505 28.3231C121.353 28.445 121.226 28.5956 121.133 28.7663C121.103 28.8387 121.054 28.9015 120.991 28.9479C120.928 28.9943 120.854 29.0226 120.776 29.0296Z" fill="#101121" />
      <path d="M127.657 27.2323C127.579 27.2373 127.5 27.2211 127.429 27.1855C127.377 27.1611 127.33 27.1265 127.291 27.0837C127.252 27.0408 127.223 26.9907 127.204 26.9362C127.184 26.8817 127.176 26.8239 127.18 26.7662C127.184 26.7086 127.199 26.6522 127.224 26.6005C127.504 26.0529 127.988 25.6372 128.572 25.4435C129.155 25.2497 129.792 25.2934 130.343 25.565C130.403 25.581 130.458 25.6093 130.506 25.6481C130.554 25.687 130.593 25.7355 130.621 25.7904C130.649 25.8454 130.665 25.9056 130.668 25.9672C130.671 26.0287 130.661 26.0902 130.639 26.1477C130.616 26.2051 130.582 26.2572 130.538 26.3006C130.495 26.3439 130.442 26.3776 130.385 26.3993C130.327 26.4211 130.265 26.4304 130.204 26.4268C130.142 26.4231 130.082 26.4066 130.027 26.3782C129.683 26.19 129.277 26.1459 128.9 26.2555C128.523 26.3651 128.205 26.6196 128.014 26.9632C127.983 27.0349 127.934 27.0973 127.871 27.1445C127.809 27.1916 127.735 27.2219 127.657 27.2323Z" fill="#101121" />
      <path d="M114.714 26.766L117.382 31.9786L118.81 31.5574L119.044 24.4961L114.714 26.766Z" fill="#29296E" />
      <path d="M119.02 31.3625C118.861 31.118 118.621 30.9377 118.342 30.8535C117.756 30.6839 116.773 30.5259 116.165 31.2572C115.269 32.3337 117.487 34.7674 119.325 33.2814C119.336 33.2814 119.828 32.673 119.02 31.3625Z" fill="#BE9168" />
      <path d="M127.628 32.3C127.424 32.4217 127.194 32.4926 126.956 32.5068C126.719 32.5211 126.482 32.4782 126.265 32.3819C126.234 32.3657 126.211 32.3385 126.201 32.3058C126.19 32.2732 126.192 32.2376 126.206 32.2064C126.225 32.1865 126.25 32.1729 126.278 32.1677C126.305 32.1625 126.333 32.1658 126.358 32.1772C126.601 32.2856 126.872 32.3111 127.13 32.2498C127.388 32.1884 127.619 32.0436 127.786 31.8378C127.868 31.7685 127.923 31.6737 127.944 31.5687C127.944 31.481 127.821 31.3757 127.663 31.2294C126.979 30.6521 126.519 29.8529 126.364 28.9712C126.357 28.9371 126.364 28.9016 126.382 28.8722C126.401 28.8427 126.43 28.8216 126.464 28.8132C126.497 28.8063 126.532 28.8129 126.56 28.8315C126.589 28.8502 126.609 28.8794 126.616 28.9127C126.771 29.7348 127.21 30.4762 127.856 31.0071C128.061 31.1885 128.219 31.3289 128.225 31.5278C128.223 31.6153 128.202 31.7013 128.163 31.78C128.125 31.8587 128.07 31.9283 128.003 31.9841C127.893 32.1061 127.767 32.2124 127.628 32.3Z" fill="#8C634D" />
      <path d="M129.41 35.3147C129.41 35.3147 126.314 35.2429 124.46 36.0645C124.46 36.0645 124.731 34.2373 126.491 33.9725C128.25 33.7076 129.41 35.3147 129.41 35.3147Z" fill="white" />
      <path d="M129.869 22.0086C129.869 22.0086 131.092 22.7107 131.818 27.543C131.818 27.543 131.719 22.0905 131.057 21.5523C130.396 21.0141 129.869 22.0086 129.869 22.0086Z" fill="#29296E" />
      <path d="M116.803 16.7198C118.4 14.9296 121.338 15.0876 123.239 13.6191C122.636 14.853 121.809 15.964 120.799 16.8953L129.255 14.444C128.385 15.3265 127.43 16.122 126.405 16.8193C128.602 17.0075 130.812 16.9938 133.006 16.7783C133.086 16.9656 133.121 17.1691 133.107 17.3724C133.094 17.5757 133.033 17.773 132.93 17.9484C132.105 19.9901 131.087 22.2308 129.05 23.0557C128.408 23.2898 127.732 23.4202 127.049 23.4418C124.351 23.5881 121.373 22.927 119.143 24.4481C118.108 25.1559 116.803 27.6014 115.351 27.2503C114.035 26.911 111.846 22.2717 116.803 16.7198Z" fill="#29296E" />
      <path d="M136.06 44.2344C143.281 45.89 150.877 60.5801 154.481 62.9553C158.186 65.3657 174.325 66.4246 174.325 66.4246L170.814 71.8771C170.814 71.8771 153.487 73.796 148.437 72.1052C145.324 71.058 140.18 66.8692 138.641 64.6753C131.859 54.917 136.06 44.2344 136.06 44.2344Z" fill="#167D2A" />
      <path d="M193.472 62.5845L191.734 58.543L101.847 97.1893L103.586 101.231L193.472 62.5845Z" fill="#29296E" />
      <path d="M103.689 101.625L101.676 96.7989L136.722 81.7344L138.735 86.5609L103.689 101.625Z" fill="#3F4DA0" />
      <path opacity="0.3" d="M214.508 5.61106C222.11 2.34295 230.511 1.40196 238.648 2.90708C246.785 4.4122 254.293 8.29584 260.222 14.0669C266.151 19.8379 270.235 27.2372 271.958 35.329C273.681 43.4207 272.966 51.8416 269.902 59.5267C266.838 67.2118 261.563 73.816 254.745 78.5041C247.927 83.1922 239.871 85.7537 231.597 85.8646C223.323 85.9755 215.201 83.6309 208.26 79.1272C201.318 74.6236 195.868 68.1632 192.599 60.563C190.429 55.5166 189.274 50.0922 189.2 44.5995C189.127 39.1068 190.136 33.6534 192.17 28.5506C194.205 23.4478 197.224 18.7957 201.057 14.8597C204.89 10.9237 209.461 7.78103 214.508 5.61106Z" fill="#8197CD" />
      <path d="M190.569 61.4291C194.009 69.4322 199.746 76.2356 207.054 80.9788C214.362 85.7221 222.913 88.192 231.626 88.0763C240.338 87.9607 248.82 85.2646 256 80.329C263.179 75.3935 268.734 68.4402 271.96 60.3485C275.187 52.2568 275.941 43.3903 274.127 34.8701C272.313 26.35 268.012 18.5591 261.769 12.4826C255.526 6.40617 247.621 2.31715 239.053 0.732718C230.485 -0.851717 221.64 0.139597 213.636 3.58129C202.914 8.20528 194.464 16.8924 190.139 27.737C185.815 38.5816 185.969 50.6982 190.569 61.4291ZM214.947 6.6293C222.344 3.44441 230.52 2.52407 238.44 3.98466C246.361 5.44524 253.67 9.22115 259.444 14.8349C265.218 20.4486 269.197 27.6481 270.878 35.5228C272.56 43.3976 271.868 51.5939 268.889 59.0753C265.911 66.5568 260.781 72.9873 254.148 77.5538C247.514 82.1203 239.675 84.6175 231.622 84.7298C223.568 84.8421 215.663 82.5644 208.904 78.1847C202.146 73.805 196.838 67.52 193.653 60.1245C189.386 50.2077 189.232 39.0031 193.225 28.973C197.218 18.943 205.031 10.9084 214.947 6.63515V6.6293Z" fill="#29296E" />
      <path d="M177.315 72.1054C177.315 72.1054 175.196 67.6709 175.782 67.2906C176.367 66.9104 178.789 69.9759 178.772 69.9174C178.754 69.8589 176.753 66.2493 177.356 65.4829C177.958 64.7165 180.586 68.3554 180.586 68.3554C180.586 68.3554 178.462 65.1143 178.906 64.6112C179.351 64.1081 181.715 66.9864 181.92 66.8285C182.125 66.6705 179.895 64.3479 180.416 63.6986C180.937 63.0492 184.196 66.2083 184.003 67.1268C183.945 67.3919 183.796 67.6284 183.583 67.796C183.369 67.9635 183.104 68.0517 182.833 68.0453C182.833 68.0453 182.336 69.5489 181.475 69.66C181.475 69.66 180.106 71.3391 179.258 70.8827C179.269 70.8827 178.778 72.7139 177.315 72.1054Z" fill="#BE9168" />
      <path d="M116.615 85.0391C116.615 85.0391 120.887 85.5422 122.397 85.8464C123.907 86.1506 125.598 85.3199 125.726 86.2033C125.855 87.0867 122.953 87.701 122.953 87.701C122.953 87.701 125.41 89.6491 126.253 90.1347C127.096 90.6203 127.827 91.3925 127.306 91.8898C126.3 92.8375 123.877 90.5559 123.877 90.5559C123.877 90.5559 127.353 92.1413 126.464 93.0481C125.721 93.8087 122.262 91.7552 122.262 91.7552C122.262 91.7552 125.522 92.7439 125.563 93.5103C125.563 93.9491 125.563 94.1714 124.205 93.8438L122.789 93.4986C123.248 93.6902 123.617 94.0503 123.819 94.5049C123.977 95.0021 123.649 95.5287 121.882 94.8208C119.746 93.9725 115.913 91.0356 114.567 88.6721L116.615 85.0391Z" fill="#BE9168" />
      <path d="M108.803 45.9316C99.8268 50.1907 92.8809 71.0353 95.2157 77.1372C97.9543 84.2804 113.9 89.885 113.9 89.885L117.71 83.0752C117.71 83.0752 105.924 76.0549 105.877 74.7268C105.825 72.7787 113.596 62.9619 113.596 62.9619L108.803 45.9316Z" fill="#167D2A" />
      <path d="M113.9 89.9901C113.919 89.9905 113.938 89.9859 113.954 89.9766C113.971 89.9674 113.984 89.9538 113.994 89.9375L117.803 83.1453C117.815 83.1215 117.818 83.0943 117.812 83.0686C117.805 83.0428 117.79 83.0202 117.768 83.0049L108.833 77.2657C108.809 77.2516 108.781 77.2468 108.754 77.2522C108.727 77.2576 108.703 77.2728 108.686 77.295C108.679 77.3068 108.674 77.3201 108.672 77.3339C108.669 77.3477 108.67 77.3618 108.673 77.3755C108.676 77.3891 108.682 77.4019 108.691 77.4132C108.699 77.4245 108.709 77.434 108.721 77.4412L117.581 83.1277L113.865 89.7503L110.354 88.3579C110.341 88.3523 110.328 88.3493 110.314 88.3493C110.3 88.3492 110.286 88.352 110.274 88.3575C110.261 88.363 110.25 88.3711 110.24 88.3812C110.231 88.3914 110.224 88.4033 110.219 88.4164C110.214 88.429 110.211 88.4426 110.211 88.4564C110.211 88.4702 110.214 88.4839 110.219 88.4965C110.225 88.5092 110.233 88.5206 110.243 88.5299C110.253 88.5393 110.265 88.5465 110.278 88.5509L113.853 89.9843L113.9 89.9901Z" fill="#101121" />
      <path d="M143.328 69.1741C143.342 69.1727 143.355 69.1685 143.368 69.1618C143.38 69.1551 143.391 69.1461 143.399 69.1352C143.408 69.1243 143.415 69.1118 143.418 69.0984C143.422 69.085 143.423 69.071 143.422 69.0571L142.679 61.4517C142.677 61.4379 142.673 61.4244 142.666 61.4122C142.66 61.4 142.651 61.3892 142.64 61.3805C142.629 61.3718 142.616 61.3653 142.603 61.3615C142.589 61.3577 142.575 61.3565 142.562 61.3581C142.548 61.3588 142.535 61.3623 142.522 61.3684C142.51 61.3745 142.499 61.383 142.491 61.3934C142.482 61.4038 142.475 61.4159 142.471 61.429C142.468 61.442 142.466 61.4558 142.468 61.4693L143.211 69.0747C143.212 69.0891 143.216 69.1031 143.222 69.116C143.228 69.129 143.237 69.1404 143.248 69.1497C143.259 69.1591 143.272 69.166 143.286 69.1702C143.299 69.1744 143.314 69.1758 143.328 69.1741Z" fill="#101121" />
    </g>
    <defs>
      <clipPath id="clip0_1981_8074">
        <rect width="296.299" height="147.089" fill="white" transform="translate(0 24.043)" />
      </clipPath>
      <clipPath id="clip1_1981_8074">
        <rect width="326" height="18.3861" fill="white" transform="translate(0 166.889)" />
      </clipPath>
      <clipPath id="clip2_1981_8074">
        <rect width="180.325" height="176.082" fill="white" transform="translate(94.7593)" />
      </clipPath>
    </defs>
  </svg>
</template>
