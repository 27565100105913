export type environmentNames =
  | 'local'
  | 'quality-assurance'
  | 'user-acceptance-testing'
  | 'production';

export const domains = {
  local: {
    gradguard: 'http://gradguard.local',
    account: 'http://account.gradguard.local',
    api: 'http://api.gradguard.local',
    cortana: 'http://cortana.gradguard.local',
    harmon: 'http://harmon.gradguard.local',
  },
  'quality-assurance': {
    gradguard: 'https://home.qa.gradguard.com',
    account: 'https://account.qa.gradguard.com',
    api: 'https://apiqa.gradguard.com',
    cortana: 'https://cortana.qa.gradguard.com',
    harmon: 'https://harmon.qa.gradguard.com',
  },
  'user-acceptance-testing': {
    gradguard: 'https://home.uat.gradguard.com',
    account: 'https://account.uat.gradguard.com',
    api: 'https://apiuat.gradguard.com',
    cortana: 'https://cortana.uat.gradguard.com',
    harmon: 'https://harmon.uat.gradguard.com',
  },
  production: {
    gradguard: 'https://gradguard.com',
    account: 'https://account.gradguard.com',
    api: 'https://api.gradguard.com',
    cortana: 'https://cortana.gradguard.com',
    harmon: 'https://harmon.internal.gradguard.com',
  },
};
