import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5taQjcJ3oK from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import slideovers_2NIjZ3dBbn from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_FeYNAF2WLE from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_mI6xh0VV9a from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_x7So6BU7bH from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8brYH9QEed from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import dayjs_a5ldCQnHbu from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/packages/ui/plugins/dayjs.ts";
import fontawesome_PmZUEhgVqr from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/packages/ui/plugins/fontawesome.ts";
import i18n_jMi0mQMboK from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/packages/ui/plugins/i18n.ts";
import maska_7u5WcieYKK from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/packages/ui/plugins/maska.ts";
import dayjs_pGiXRjcsJO from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/dayjs.ts";
import fontawesome_cn2c4tOOHz from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/fontawesome.ts";
import i18n_VfGcjrvSkj from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/i18n.ts";
import maska_UHaKf2z1iQ from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/maska.ts";
import urql_plugin_iHo3jeDrip from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/urql.plugin.ts";
import ui_rjNXKDZLQ9 from "/home/runner/work/nuxt-monorepo/nuxt-monorepo/apps/partnerPortal/plugins/ui.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_5taQjcJ3oK,
  slideovers_2NIjZ3dBbn,
  modals_FeYNAF2WLE,
  colors_mI6xh0VV9a,
  plugin_client_x7So6BU7bH,
  plugin_8brYH9QEed,
  dayjs_a5ldCQnHbu,
  fontawesome_PmZUEhgVqr,
  i18n_jMi0mQMboK,
  maska_7u5WcieYKK,
  dayjs_pGiXRjcsJO,
  fontawesome_cn2c4tOOHz,
  i18n_VfGcjrvSkj,
  maska_UHaKf2z1iQ,
  urql_plugin_iHo3jeDrip,
  ui_rjNXKDZLQ9
]